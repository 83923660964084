import { useState, useEffect } from 'react';

import dayjs from 'dayjs';
import { generateFilter, dataGridColumns } from '../../utils/helper';
import {
  DatePickerUI,
  DataGridUI,
  DataGridIdentifiers,
  FormLabelUI,
  DebouncedInputUI,
  AutoCompleteSelectUI,
} from '../Interface';
import complaintService from '../../service/complaint.service';
import { ColorPicker } from '../../config/ColorPicker';
import useDataGridDynamicStyles from '../../hooks/useDataGridDynamicStyles';

const ComplaintReports = ({
  refetch,
  values,
  viewField,
  operatorsDisabled,
  autocompleteData,
  rowSelection,
  submenu,
}) => {
  useDataGridDynamicStyles(ColorPicker);
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [{ field: 'date', sort: 'desc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });
  const [filterFormState, setFilterFormState] = useState({
    prefixedId: '',
    date: null,
    customer: '',
    item: '',
    statusId: '',
  });

  const conditions = {
    like: ['prefixedId'],
  };

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'deletedBy',
        value: null,
      },
    ];

    const newFilter = generateFilter(filterFormState, conditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
  }, [filterFormState]);

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getList();
    }
  }, [query]);

  useEffect(() => {
    if (refetchTable && query.filter?.length > 0) {
      getList();
    }
  }, [refetchTable]);

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const onPaginitonChange = (paginationModel) => {
    setQuery((prev) => {
      return { ...prev, pagination: { paginationModel } };
    });
  };

  const handleSortModelChange = (sortModel) => {
    setQuery((prev) => {
      const paginationModel = {
        pageSize: prev.pagination.paginationModel.pageSize,
        page: 0,
      };

      return {
        ...prev,
        sorting: { sortModel },
        pagination: { paginationModel },
      };
    });
  };

  const getList = () => {
    complaintService.getComplaints(query).then((data) => {
      setReports(data);
      setRefetchTable(false);
    });
  };

  const columns = dataGridColumns([
    {
      field: 'prefixedId',
      headerName: 'Sorszám',
      flex: 1,
    },
    {
      field: 'date',
      headerName: 'Érkezés dátuma',
      flex: 1,
      renderCell: (params) => {
        return dayjs(params.row.date).format('YYYY.MM.DD.');
      },
    },
    {
      field: 'customer',
      headerName: 'Vevő neve',
      flex: 1,
      renderCell: (params) => {
        return params.row?.customerData?.companyName;
      },
    },
    {
      field: 'item',
      headerName: 'Reklamált termék megnevezése',
      flex: 1,
      renderCell: (params) => {
        return params.row?.itemData?.itemName;
      },
    },
    {
      field: 'statusId',
      headerName: 'Státusz',
      flex: 1,
      renderCell: (params) => {
        return params.row?.status?.itemName;
      },
    },
  ]);

  const getRowClassName = (params) => {
    const isSelected = rowSelectionModel.includes(params.id);
    const statusColor = ColorPicker.find((col) => col.colorName === params?.row?.status.color);

    if (isSelected) {
      return statusColor ? `color-selected-${statusColor.colorName}` : '';
    }
    return statusColor ? `color-${statusColor.colorName}` : '';
  };

  return (
    <div className="flex  overflow-hidden m-4 ">
      <div className="h-full w-full  overflow-x-auto ">
        <div className="grid grid-cols-4 gap-x-4 mx-4">
          <div className="col-span-2 md:col-span-1">
            <DebouncedInputUI
              label={<FormLabelUI text="Sorszám" />}
              debounceMs={800}
              setQuickFilterSearchValue={(newValue) => {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  prefixedId: newValue,
                }));
              }}
              quickFilterSearchValue={filterFormState.prefixedId}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <DatePickerUI
              label={<FormLabelUI text="Érkezés dátuma" />}
              value={filterFormState.date}
              onChange={(newDate) => {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  date: newDate ? dayjs(newDate).startOf('hour').add(newDate.utcOffset(), 'minute') : null,
                }));
              }}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <AutoCompleteSelectUI
              id="customer"
              fullWidth
              label={<FormLabelUI text="Vevő neve" />}
              variant="standard"
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    customer: '',
                  }));
                } else {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    customer: newVal.value,
                  }));
                }
              }}
              selectedValue={filterFormState.customer}
              dataset={autocompleteData.businessPartners}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <AutoCompleteSelectUI
              name="item"
              fullWidth
              label={<FormLabelUI text="Reklamált termék megnevezése" />}
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    item: '',
                  }));
                } else {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    item: newVal.value,
                  }));
                }
              }}
              variant="standard"
              selectedValue={filterFormState.item}
              dataset={autocompleteData.items}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <AutoCompleteSelectUI
              id="status"
              label={<FormLabelUI text="Státusz" />}
              selectedValue={filterFormState.statusId}
              table={['statusitems', 'status']}
              listType={{ id: 'itemId', name: 'itemName' }}
              joins={'statusitems.statusId = status.statusId'}
              conditions={[`statusitems.archive = 'N'`, `status.subMenuId = ${submenu}`]}
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    statusId: '',
                  }));
                } else {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    statusId: newVal.value,
                  }));
                }
              }}
            />
          </div>
        </div>
        <div className="inline-block w-full border-b border-gray-200 align-middle mt-4 overflow-y-auto overflow-x-auto ">
          <DataGridUI
            datagridIdentifier={DataGridIdentifiers.complaints}
            initialState={query}
            rows={reports.rows}
            rowCount={reports.rowCount}
            columns={columns}
            onRowClick={(rowData) => {
              if (!viewField) return;
              setSelectedValues(rowData.row);
            }}
            paginationMode="server"
            onPaginationModelChange={onPaginitonChange}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            disableRowSelectionOnClick={!viewField}
            height={700}
            getRowClassName={getRowClassName}
          />
        </div>
      </div>
    </div>
  );
};

export default ComplaintReports;
